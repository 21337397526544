import React from "react";
import { useSelect } from "downshift";
import type { TypeMenuItemProps } from "../MenuItem";
import { useMenuChildren } from "../hooks/useMenuChildren";
import { useDownshiftDefaults } from "../hooks/useDownshiftDefaults";
import { itemToString } from "../utils/downshiftDefaults";
import { reduceReducers } from "../utils/reduceReducers";
import { MenuRoot, type TypeMenuRootProps } from "../MenuRoot";
import type { TypeSingleSelectMenuProps } from "./SingleSelectMenuTypes";

/**
 * @link https://seeds.sproutsocial.com/components/singleselect-menu/
 *
 * @description A SingleSelectMenu allows a single selection of a {@link https://github.com/sproutsocial/seeds/tree/dev/seeds-react/seeds-react-menu/src/MenuItem | MenuItem} from a list or groups or lists.
 *
 * **To trigger actions from a menu:**
 * @see {@link https://github.com/sproutsocial/seeds/tree/dev/seeds-react/seeds-react-menu/src/ActionMenu | ActionMenu}
 */

export const SingleSelectMenu = <
  I extends TypeMenuItemProps = TypeMenuItemProps
>({
  children: childrenProp,
  stateReducer: externalStateReducer,
  menuItems,
  MenuItemComponent,
  MenuRootComponent = MenuRoot,
  ...useSelectProps
}: TypeSingleSelectMenuProps<I>) => {
  /** Get a list of menuItems from the children */
  const { allMenuItems, menuItemsMap, children } = useMenuChildren({
    children: childrenProp,
    menuItems,
    MenuItemComponent,
  });
  const { defaultDownshiftProps, ...restDefaults } = useDownshiftDefaults({
    isCombobox: false,
  });

  /**
   * Destructure Downshift props and call the core useSelect with items and state handler
   */
  const { isOpen, ...useSelectReturnProps } = useSelect({
    ...defaultDownshiftProps.select,
    items: allMenuItems,
    itemToString,
    stateReducer: reduceReducers(
      defaultDownshiftProps.select.stateReducer,
      externalStateReducer
    ),
    ...useSelectProps,
  });

  return (
    <MenuRootComponent
      {...{
        isListbox: true,
        items: allMenuItems,
        itemsMap: menuItemsMap,
        itemToString,
        isOpen,
        ...useSelectProps,
        ...useSelectReturnProps,
        ...restDefaults,
      }}
    >
      {children}
    </MenuRootComponent>
  );
};
