import React from "react";
import { useCombobox, useMultipleSelection } from "downshift";
import { useMenuChildren } from "../hooks/useMenuChildren";
import {
  itemToString as defaultItemToString,
  itemToKey as defaultItemToKey,
} from "../utils/downshiftDefaults";
import { reduceReducers } from "../utils/reduceReducers";
import { useDownshiftDefaults } from "../hooks/useDownshiftDefaults";
import {
  useItemFiltering,
  getDefaultGetIsItemVisible,
  defaultGetShouldFilter,
  type TypeGetShouldFilterFn,
} from "../hooks/useItemFiltering";
import { getSelectedItemIds } from "../MenuContext";
import { MenuRoot } from "../MenuRoot";

import type { TypeMenuItemProps } from "../MenuItem";
import type { TypeMultiAutocompleteProps } from "./AutocompleteTypes";

export const MultiAutocomplete = <
  I extends TypeMenuItemProps = TypeMenuItemProps
>({
  children: childrenProp,
  stateReducer: externalStateReducer,
  menuItems,
  MenuItemComponent,
  MenuRootComponent = MenuRoot,
  itemToString = defaultItemToString,
  itemToKey = defaultItemToKey,
  getIsItemVisible = getDefaultGetIsItemVisible(itemToString),
  showSelectedItemsInDropdown = false,
  initialSelectedItems = [],
  onStateChange,
  onSelectedItemsChange,
  getA11yMultiStatusMessage,
  onMultiSelectStateChange,
  multiSelectStateReducer,
  ...useComboboxProps
}: TypeMultiAutocompleteProps<I>) => {
  /** Get a list of menuItems and children */
  const { allMenuItems, children } = useMenuChildren({
    children: childrenProp,
    menuItems,
    MenuItemComponent,
  });
  const [selectedItems, setSelectedItems] =
    React.useState(initialSelectedItems);
  const [selectedItemIds, setSelectedItemIds] = React.useState<
    Record<string, boolean>
  >(getSelectedItemIds(initialSelectedItems));

  // function that checks that item should be visible based on the selected items
  const getIsItemVisibleMulti = React.useCallback<typeof getIsItemVisible>(
    (item, inputValue) => {
      return (
        (showSelectedItemsInDropdown || !selectedItemIds[item.id]) &&
        getIsItemVisible(item, inputValue)
      );
    },
    [selectedItemIds, showSelectedItemsInDropdown, getIsItemVisible]
  );

  // function that checks that item should be filtered
  const getShouldFilterMulti = React.useCallback<TypeGetShouldFilterFn>(
    (args) => {
      return (
        (!showSelectedItemsInDropdown &&
          Object.keys(selectedItemIds).length > 0) ||
        defaultGetShouldFilter(args)
      );
    },
    [selectedItemIds, showSelectedItemsInDropdown, getIsItemVisible]
  );

  const { setHiddenItemsMap, defaultDownshiftProps, ...restDefaults } =
    useDownshiftDefaults({
      isCombobox: true,
      isMulti: true,
      // handles the prop name mapping for multi select
      multiSelectProps: {
        getA11yMultiStatusMessage,
        onMultiSelectStateChange,
        multiSelectStateReducer,
        ...useComboboxProps,
      },
    });

  // used to handle filtering of items
  const { updateFilteredItems } = useItemFiltering({
    allMenuItems,
    itemToString,
    getIsItemVisible: getIsItemVisibleMulti,
    getShouldFilter: getShouldFilterMulti,
    setHiddenItemsMap,
  });

  // update the filtered items when the selectedItemIds changes
  React.useEffect(() => {
    // the inputValue should be empty whenever the selected items change
    // if the inputValue is needed here then it will need to be stored on a new state
    updateFilteredItems({ inputValue: "" });
  }, [selectedItemIds]);

  const useMultipleSelectionReturnProps = useMultipleSelection({
    ...defaultDownshiftProps.multi,
    itemToKey,
    selectedItems,
    onSelectedItemsChange: ({ selectedItems, ...changes }) => {
      setSelectedItems(selectedItems);
      setSelectedItemIds(getSelectedItemIds(selectedItems));
      onSelectedItemsChange &&
        onSelectedItemsChange({ selectedItems, ...changes });
    },
  });

  /**
   * Destructure Downshift props and call the core useCombobox with items and state handler
   */
  const { isOpen, ...useComboboxReturnProps } = useCombobox<
    (typeof allMenuItems)[number]
  >({
    ...defaultDownshiftProps.combobox,
    items: allMenuItems,
    itemToString,
    itemToKey,
    stateReducer: reduceReducers(
      defaultDownshiftProps.combobox.stateReducer,
      externalStateReducer
    ),
    onStateChange(changes) {
      const { type, selectedItem: newSelectedItem, inputValue } = changes;
      switch (type) {
        // Add the selected item to the selected items list
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
          if (newSelectedItem) {
            const newSelectedItems = [...selectedItems, newSelectedItem];
            setSelectedItems(newSelectedItems);
            setSelectedItemIds({
              ...selectedItemIds,
              [newSelectedItem.id]: true,
            });
            onSelectedItemsChange &&
              onSelectedItemsChange({
                selectedItems: newSelectedItems,
                type: useMultipleSelection.stateChangeTypes
                  .FunctionSetSelectedItems,
              });
          }
          break;
        default:
          break;
      }
      // Update the filtered items based on the input value
      if (inputValue !== undefined) updateFilteredItems({ inputValue });
      onStateChange && onStateChange(changes);
    },
    ...useComboboxProps,
  });

  return (
    <MenuRootComponent
      {...{
        isListbox: true,
        items: allMenuItems,
        itemToString,
        isOpen,
        initialSelectedItems,
        onStateChange,
        onSelectedItemsChange,
        getA11yMultiStatusMessage,
        onMultiSelectStateChange,
        multiSelectStateReducer,
        setHiddenItemsMap,
        ...useComboboxProps,
        ...useComboboxReturnProps,
        ...useMultipleSelectionReturnProps,
        ...restDefaults,
      }}
      popoutProps={{
        focusOnContent: false,
      }}
    >
      {children}
    </MenuRootComponent>
  );
};
