import React, { useCallback, useEffect } from "react";
import styled from "styled-components";
import { Popout, type TypePopoutProps } from "@sproutsocial/seeds-react-popout";

import {
  MenuContentProvider,
  MenuToggleProvider,
  type TypeMenuContextProps,
  type TypeMenuProviderProps,
  useMenu,
} from "../MenuContext";
import { MOTION_DURATION_FAST } from "@sproutsocial/seeds-motion/unitless";

export interface TypeMenuRootOwnProps {
  menuToggleElement: React.ReactElement<any>;
  popoutProps?: Partial<
    Omit<TypePopoutProps, "isOpen" | "setIsOpen" | "content" | "children">
  >;
  width?: TypePopoutProps["width"];
}
export interface TypeMenuRootProps
  extends Partial<TypeMenuContextProps>,
    Pick<TypeMenuProviderProps, "children">,
    TypeMenuRootOwnProps {}

export const CustomPopoutContent = styled(Popout.Content)`
  padding: 0;
  margin-left: 0;
  margin-right: 0;
`;

const menuAnimationConfig = {
  initial: {
    scale: 0,
    opacity: 0,
    originX: 0,
    originY: 0,
  },
  animate: {
    opacity: 1,
    scale: 1,
    originX: 0,
    originY: 0,

    transition: {
      type: "tween",
      duration: MOTION_DURATION_FAST,
      ease: "circOut",
    },
  },
  exit: {
    opacity: 0,
    scale: 1,
    transition: {
      type: "tween",
      duration: MOTION_DURATION_FAST,
      ease: "circIn",
    },
  },
};

export interface TypeMenuPopoutProps
  extends Pick<Partial<TypeMenuContextProps>, "openMenu" | "closeMenu">,
    TypePopoutProps {}

export const MenuPopout = ({
  content,
  width = "300px",
  isOpen,
  openMenu,
  closeMenu,
  focusLockProps,
  ...popoutProps
}: TypeMenuPopoutProps) => {
  const setIsOpen = useCallback(
    (newIsOpen: boolean) => {
      newIsOpen ? openMenu?.() : closeMenu?.();
    },
    [openMenu, closeMenu]
  );

  return (
    <Popout
      isOpen={!!isOpen}
      setIsOpen={setIsOpen}
      content={(props) => (
        <CustomPopoutContent width={popoutProps.fullWidth ? "initial" : width}>
          {typeof content === "function" ? content(props) : content}
        </CustomPopoutContent>
      )}
      focusLockProps={{
        // correct jerking motion when scrolling while the Popout is open
        // returnFocus: false,
        crossFrame: false,
        ...focusLockProps,
      }}
      disableWrapperAria
      animationConfig={menuAnimationConfig}
      {...popoutProps}
    />
  );
};

/**
 * @link https://seeds.sproutsocial.com/components/menu-root/
 *
 * @description MenuRoot is a utility component used to handle {@link https://github.com/sproutsocial/seeds/blob/dev/seeds-react/seeds-react-menu/src/MenuContext.tsx | MenuContext}. This component should rarely, if ever, be used directly unless building a custom menu type.
 */

export const MenuRoot = ({
  children,
  menuToggleElement,
  popoutProps: { placement = "bottom", focusLockProps, ...popoutProps } = {},
  width = "300px",
  ...contextProps
}: TypeMenuRootProps) => {
  const {
    isOpen,
    openMenu,
    closeMenu,
    inputValue,
    hiddenItemsCount = 0,
    items,
  } = contextProps;
  const setIsOpen = useCallback(
    (newIsOpen: boolean) => {
      newIsOpen ? openMenu?.() : closeMenu?.();
    },
    [openMenu, closeMenu]
  );

  useEffect(() => {
    if (!inputValue) return;

    if (items && hiddenItemsCount >= items.length && isOpen) {
      closeMenu?.();
    }
  }, [hiddenItemsCount, items?.length, isOpen, inputValue]);

  const menuContext = useMenu(contextProps);

  return (
    <MenuPopout
      placement={placement}
      isOpen={!!isOpen}
      openMenu={openMenu}
      closeMenu={closeMenu}
      content={
        <MenuContentProvider menuContext={menuContext}>
          {children}
        </MenuContentProvider>
      }
      {...popoutProps}
    >
      {(toggleProps) => (
        <MenuToggleProvider menuContext={{ ...menuContext, ...toggleProps }}>
          {menuToggleElement}
        </MenuToggleProvider>
      )}
    </MenuPopout>
  );
};

export const MenuRootNoPopout = ({
  children,
  menuToggleElement,
  ...contextProps
}: TypeMenuRootProps) => {
  const menuContext = useMenu(contextProps);

  return (
    <MenuContentProvider menuContext={menuContext}>
      {children}
    </MenuContentProvider>
  );
};
