import { MenuHeader } from "../MenuHeader/index";
import { useNestedMenuContext } from "./NestedMenuContext";
import { Button } from "@sproutsocial/seeds-react-button";
import { Icon } from "@sproutsocial/seeds-react-icon";
import type { TypeNestedMenuHeaderProps } from "./NestedMenuTypes";
import { AnimatePresence, motion } from "motion/react";

export const NestedMenuHeader = ({
  backArrowLabel,
  ...props
}: TypeNestedMenuHeaderProps) => {
  const { goBack, selectedMenuPath, animationSpeed } = useNestedMenuContext();
  const showBackArrow = selectedMenuPath.length > 1;
  return (
    <MenuHeader
      leftAction={
        <AnimatePresence mode="wait">
          <motion.div
            key={`${selectedMenuPath}-header--button`}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{
              opacity: 0,
              transition: { duration: animationSpeed },
            }}
          >
            {showBackArrow ? (
              <Button onClick={goBack} ariaLabel={backArrowLabel}>
                <Icon name="arrow-left" />
              </Button>
            ) : undefined}
          </motion.div>
        </AnimatePresence>
      }
      {...props}
    />
  );
};
