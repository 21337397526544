import React from "react";
import { useSelect, useMultipleSelection } from "downshift";
import { useMenuChildren } from "../hooks/useMenuChildren";
import { useDownshiftDefaults } from "../hooks/useDownshiftDefaults";
import { itemToString } from "../utils/downshiftDefaults";
import { reduceReducers } from "../utils/reduceReducers";
import { MenuRoot, type TypeMenuRootProps } from "../MenuRoot";
import { type TypeMenuItemProps } from "../MenuItem";
import type { TypeMultiSelectMenuProps } from "./MultiSelectMenuTypes";
import type { TypeInternalItemProps } from "../types";

/**
 * @link https://seeds.sproutsocial.com/components/multiselect-menu/
 *
 * @description A MultiSelectMenu allows multiple {@link https://github.com/sproutsocial/seeds/tree/dev/seeds-react/seeds-react-menu/src/MenuItem | MenuItems} to be selected from a list or groups of lists.
 *
 * @see {@link https://seeds.sproutsocial.com/components/menu-group/ | MenuGroup}
 */

export const MultiSelectMenu = <
  I extends TypeMenuItemProps = TypeMenuItemProps
>({
  children: childrenProp,
  menuItems,
  MenuItemComponent,
  onSelectedItemChange: externalOnSelectedItemChange,
  stateReducer: externalStateReducer,
  MenuRootComponent = MenuRoot,
  getA11yMultiStatusMessage,
  onMultiSelectStateChange,
  multiSelectStateReducer,
  ...useSelectProps
}: TypeMultiSelectMenuProps<I>) => {
  const { allMenuItems, menuItemsMap, children } = useMenuChildren({
    children: childrenProp,
    menuItems,
    MenuItemComponent,
  });
  const { defaultDownshiftProps, ...restDefaults } = useDownshiftDefaults({
    isCombobox: false,
    isMulti: true,
    // handles the prop name mapping for multi select
    multiSelectProps: {
      getA11yMultiStatusMessage,
      onMultiSelectStateChange,
      multiSelectStateReducer,
      ...useSelectProps,
    },
  });

  const {
    getSelectedItemProps,
    addSelectedItem,
    removeSelectedItem,
    selectedItems,
    getDropdownProps,
    ...useMultipleSelectionReturnProps
  } = useMultipleSelection<TypeInternalItemProps>(defaultDownshiftProps.multi);

  const { isOpen, ...useSelectReturnProps } = useSelect({
    ...defaultDownshiftProps.select,
    items: allMenuItems,
    itemToString,
    selectedItem: null,
    defaultHighlightedIndex: 0,
    stateReducer: reduceReducers(
      defaultDownshiftProps.select.stateReducer,
      externalStateReducer
    ),
    onSelectedItemChange: (changes) => {
      const { type, selectedItem: newSelectedItem } = changes;
      switch (type) {
        case useSelect.stateChangeTypes.ToggleButtonKeyDownEnter:
        case useSelect.stateChangeTypes.ToggleButtonKeyDownSpaceButton:
        case useSelect.stateChangeTypes.ItemClick:
          if (!newSelectedItem) break;
          if (selectedItems?.find((item) => item?.id === newSelectedItem.id)) {
            removeSelectedItem(newSelectedItem);
          } else {
            addSelectedItem(newSelectedItem);
          }
          // If the newly selected item's group is single select, remove any other selected items in that group.
          if (newSelectedItem.menuGroupProps?.isSingleSelect) {
            const groupItems = selectedItems.filter(
              (item) =>
                item.menuGroupProps?.id === newSelectedItem.menuGroupProps?.id
            );
            groupItems.forEach((item) => removeSelectedItem(item));
          }
          break;
        default:
          break;
      }

      // Call the consumer's onSelectedItemChange callback
      externalOnSelectedItemChange?.(changes);
    },
    ...useSelectProps,
  });

  return (
    <MenuRootComponent
      {...{
        isListbox: true,
        items: allMenuItems,
        itemsMap: menuItemsMap,
        itemToString,
        isOpen,
        getSelectedItemProps,
        addSelectedItem,
        removeSelectedItem,
        selectedItems,
        getDropdownProps,
        getA11yMultiStatusMessage,
        onMultiSelectStateChange,
        multiSelectStateReducer,
        ...useSelectProps,
        ...useSelectReturnProps,
        ...useMultipleSelectionReturnProps,
        ...restDefaults,
      }}
    >
      {children}
    </MenuRootComponent>
  );
};
