import React from "react";
import { type TypeMenuRootProps } from "../MenuRoot";
import { MenuContentProvider, useMenu } from "../MenuContext";
import { useNestedMenuContext } from "./NestedMenuContext";

export const NestedMenuRoot = ({
  children,
  menuToggleElement,
  ...contextProps
}: TypeMenuRootProps) => {
  const menuContext = useMenu(contextProps);
  const { setActiveMenuContext, selectedMenuPath } = useNestedMenuContext();

  React.useEffect(() => {
    setActiveMenuContext(menuContext);
  }, [
    selectedMenuPath[selectedMenuPath.length - 1],
    menuContext.selectedItem,
    menuContext.selectedItems?.length,
  ]);

  return (
    <MenuContentProvider menuContext={menuContext}>
      {children}
    </MenuContentProvider>
  );
};
