import React, { createContext, useContext } from "react";
import type { TypeNotEmptyChildren } from "../types";
import {
  defaultMenuContext,
  type TypeMenuContext,
  type TypeMenuContextProps,
} from "../MenuContext";
import { MOTION_DURATION_FAST } from "@sproutsocial/seeds-motion/unitless";

export interface TypeNestedMenuContext {
  selectedMenuPath: string[];
  setSelectedMenuId?: (id: string) => void;
  setSelectedMenuPath?: (path: string[]) => void;
  resetSelectedMenuPath?: () => void;
  goBack?: () => void;
  rootMenuContextProps: Omit<TypeMenuContextProps, "items">;
  activeMenuContext: TypeMenuContext;
  setActiveMenuContext: (context: TypeMenuContext) => void;
  animationSpeed: number;
}

const defaultNestedMenuContext: TypeNestedMenuContext = {
  selectedMenuPath: [],
  setSelectedMenuId: () => {},
  setSelectedMenuPath: () => {},
  resetSelectedMenuPath: () => {},
  goBack: () => {},
  rootMenuContextProps: {},
  activeMenuContext: defaultMenuContext,
  setActiveMenuContext: () => {},
  animationSpeed: MOTION_DURATION_FAST,
};

export interface TypeNestedMenuProviderProps extends TypeNestedMenuContext {
  children: TypeNotEmptyChildren;
}

/**
 * NestedMenuContext factory, sets the type and the initial object
 */
export const NestedMenuContext = createContext<TypeNestedMenuContext>(
  defaultNestedMenuContext
);

/**
 * Provider Component sets the context values in the provider
 */
export const NestedMenuProvider = ({
  children,
  ...nestedMenuProps
}: TypeNestedMenuProviderProps) => {
  return (
    <NestedMenuContext.Provider value={nestedMenuProps}>
      {children}
    </NestedMenuContext.Provider>
  );
};

/**
 * Utility hook for consuming NestedMenuContext
 */
export const useNestedMenuContext = (): TypeNestedMenuContext => {
  const nestedMenuContextValue = useContext(NestedMenuContext);

  if (nestedMenuContextValue === null) {
    throw new Error(
      "useNestedMenuContext must be used within a <NestedMenuContext.Provider />"
    );
  }

  return nestedMenuContextValue;
};
