import { useId, useEffect, useState } from "react";
import { AnimatePresence, LayoutGroup, motion } from "motion/react";
import { useSelect } from "downshift";

import { useDownshiftDefaults } from "../hooks/useDownshiftDefaults";
import { reduceReducers } from "../utils/reduceReducers";
import { nestedMenuStateReducer } from "../reducers/nestedMenuStateReducer";
import {
  defaultMenuContext,
  type TypeMenuContext,
  type TypeDownshiftSelectProps,
} from "../MenuContext";
import type { TypeMenuItemProps } from "../MenuItem";
import type {
  TypeNestedMenuItemProps,
  TypeNestedMenuProps,
} from "./NestedMenuTypes";
import { NestedMenuContent } from "./NestedMenuContent";
import { NestedMenuProvider } from "./NestedMenuContext";
import { NestedMenuHeader } from "./NestedMenuHeader";
import { NestedMenuItem } from "./NestedMenuItem";
import { MOTION_DURATION_FAST } from "@sproutsocial/seeds-motion/unitless";
import { NestedMenuRoot } from "./NestedMenuRoot";
import { NestedMenuPopout } from "./NestedMenuPopout";

export const NestedMenu = <
  I extends TypeMenuItemProps = TypeNestedMenuItemProps
>({
  initialMenuId,
  menus,
  onBackButtonClick,
  backArrowLabel,
  menuToggleElement,
  popoutProps = {},
  width = "300px",
  stateReducer: rootExternalStateReducer,
  id: externalNestedMenuId,
  ...useSelectProps
}: TypeNestedMenuProps<I>) => {
  /**
   * Handling the Open/Close and accessibility of the NestedMenu itself
   */
  const { defaultDownshiftProps, ...restDefaults } = useDownshiftDefaults({
    isCombobox: false,
  });
  const uniqueId = useId();
  const nestedMenuId = externalNestedMenuId ?? `nested-menu-${uniqueId}`;
  const [hasLoadedOnce, setHasLoadedOnce] = useState(false);
  const useSelectReturnProps = useSelect({
    ...defaultDownshiftProps.select,
    id: nestedMenuId,
    items: [],
    stateReducer: reduceReducers(
      defaultDownshiftProps.select.stateReducer,
      rootExternalStateReducer
    ),
    ...useSelectProps,
  });

  const rootMenuContextProps = {
    ...useSelectProps,
    ...useSelectReturnProps,
    ...restDefaults,
  };

  useEffect(() => {
    if (!useSelectReturnProps.isOpen) {
      setHasLoadedOnce(false);
    } else {
      !hasLoadedOnce && setHasLoadedOnce(true);
    }
  }, [useSelectReturnProps.isOpen]);

  // onNestedIsOpenChange handles closing the popout based on the selected menu's built in open/close logic.
  const onNestedIsOpenChange: TypeDownshiftSelectProps["onIsOpenChange"] = ({
    isOpen: newIsOpen,
  }) => {
    // Close the popout when a child menu's close logic is triggered
    !newIsOpen && useSelectReturnProps.closeMenu?.();
  };

  const nestedMenuPropsFromRoot: Partial<TypeDownshiftSelectProps> = {
    // The selected menu needs to receive this id so that the toggle element can be properly associated with it.
    id: nestedMenuId,
    onIsOpenChange: onNestedIsOpenChange,
  };

  /**
   * Handling the state of the NestedMenu content
   */
  const [selectedMenuPath, setSelectedMenuPath] = useState([initialMenuId]);
  const [activeMenuContext, setActiveMenuContext] =
    useState<TypeMenuContext>(defaultMenuContext);
  const [currentMenuId, setCurrentMenuId] = useState(initialMenuId);
  const [isAdding, setIsAdding] = useState(false);

  const setSelectedMenuId = (id: string) => {
    setIsAdding(true);
    setSelectedMenuPath((currentPath) => [...currentPath, id]);
  };

  // Set the currentMenuId reactively after the selectedMenuPath changes, so that
  // isAdding has time to be set before the animation starts.
  useEffect(() => {
    if (selectedMenuPath.length > 0) {
      const newMenuId = selectedMenuPath[selectedMenuPath.length - 1];
      setCurrentMenuId(newMenuId);
    }
  }, [selectedMenuPath.join("-")]);

  const resetSelectedMenuPath = () => {
    setSelectedMenuPath([initialMenuId]);
    setCurrentMenuId(initialMenuId);
  };

  const goBack = () => {
    setIsAdding(false);
    setSelectedMenuPath((currentPath) => currentPath.slice(0, -1));
    // user-defined callback
    onBackButtonClick?.(currentMenuId);
  };

  const {
    MenuComponent,
    menuProps,
    MenuHeaderComponent = NestedMenuHeader,
    menuHeaderProps = {},
  } = menus[currentMenuId] || {};

  const {
    children,
    menuItems,
    MenuItemComponent,
    stateReducer: externalStateReducer,
    ...restMenuProps
  } = menuProps || {};

  const stateReducer = reduceReducers(
    nestedMenuStateReducer,
    externalStateReducer
  );

  const animationSpeed = MOTION_DURATION_FAST;

  const contentVariants = {
    arrive: (custom = { hasLoadedOnce: false, isAdding: false }) => ({
      opacity: 0,
      // Start from left if is first render of root, else use default
      x: !custom?.hasLoadedOnce ? "200%" : custom?.isAdding ? "200%" : "-200%",
    }),
    enter: (custom = { hasLoadedOnce: false }) => ({
      opacity: 1,
      x: 0,
      transition: {
        type: "tween",
        duration: animationSpeed,
        ease: "circOut",
        delay: !custom.hasLoadedOnce ? animationSpeed : 0,
      },
    }),
    leave: (custom = { hasLoadedOnce: false, isAdding: false }) => ({
      opacity: 0,
      x: custom?.isAdding ? "-200%" : "200%",
      transition: {
        type: "tween",
        duration: animationSpeed,
        ease: "circIn",
      },
    }),
  };

  // This is suggested even though they are exactly the same
  const menuVariants = {
    arrive: (custom = { hasLoadedOnce: false, isAdding: false }) => ({
      opacity: 0,
      // Start from left if is first render of root, else use default
      x: !custom?.hasLoadedOnce ? "200%" : custom?.isAdding ? "200%" : "-200%",
    }),
    enter: (custom = { hasLoadedOnce: false }) => ({
      opacity: 1,
      x: 0,
      transition: {
        type: "tween",
        duration: animationSpeed,
        ease: "circOut",
        delay: !custom.hasLoadedOnce ? animationSpeed : 0,
      },
    }),
    leave: (custom = { hasLoadedOnce: false, isAdding: false }) => ({
      opacity: 0,
      x: custom?.isAdding ? "-200%" : "200%",
      transition: {
        type: "tween",
        duration: animationSpeed,
        ease: "circIn",
      },
    }),
  };

  const nestedMenuProps = {
    ...nestedMenuPropsFromRoot,
    menuToggleElement,
    stateReducer,
    MenuRootComponent: NestedMenuRoot,
    // This ensures that onNestedIsOpenChange will be triggered if the selected menu attempts to close itself.
    initialIsOpen: true,
    children: (
      // Handles the smooth animating of the content
      <AnimatePresence mode="wait">
        <motion.div
          layout="size"
          key={currentMenuId}
          variants={contentVariants}
          initial="arrive"
          animate="enter"
          exit="leave"
          custom={{ isAdding, hasLoadedOnce }}
        >
          {MenuHeaderComponent && (
            <MenuHeaderComponent
              backArrowLabel={backArrowLabel}
              {...menuHeaderProps}
            />
          )}
          {/*children takes precedence over menuItems if both are passed.*/}
          {children && children}
          {menuItems && !children && (
            <NestedMenuContent
              menuItems={menuItems}
              MenuItemComponent={MenuItemComponent ?? NestedMenuItem}
            />
          )}
        </motion.div>
      </AnimatePresence>
    ),
    ...restMenuProps,
  };

  return (
    <NestedMenuProvider
      {...{
        selectedMenuPath,
        setSelectedMenuPath,
        setSelectedMenuId,
        resetSelectedMenuPath,
        goBack,
        rootMenuContextProps,
        activeMenuContext,
        setActiveMenuContext,
        animationSpeed,
      }}
    >
      <LayoutGroup>
        <NestedMenuPopout
          content={
            MenuComponent && (
              // Handles the rerender of the component
              <AnimatePresence mode="wait">
                <motion.div
                  key={`${currentMenuId}-content`}
                  variants={menuVariants}
                  initial="arrive"
                  animate="enter"
                  exit="leave"
                  custom={{ isAdding, hasLoadedOnce }}
                >
                  <MenuComponent {...nestedMenuProps} />
                </motion.div>
              </AnimatePresence>
            )
          }
          menuToggleElement={menuToggleElement}
          width={width}
          {...popoutProps}
        />
      </LayoutGroup>
    </NestedMenuProvider>
  );
};
