import styled, { css } from "styled-components";
import { Icon } from "@sproutsocial/seeds-react-icon";
import {
  MenuToggleButton,
  type TypeMenuToggleButtonProps,
} from "./MenuToggleButton";
import { useMenuToggleContext } from "./MenuContext";

export interface TypeListboxToggleButtonProps
  extends TypeMenuToggleButtonProps {
  invalid?: boolean;
}

export const StyledListboxToggleButton = styled(
  MenuToggleButton
)<TypeListboxToggleButtonProps>`
  ${(props) =>
    props.invalid &&
    css`
      border-color: ${(props) => props.theme.colors.form.border.error};
    `}
`;

export interface TypeStyledChevronProps {
  $isOpen?: boolean;
  invalid?: boolean;
}

const StyledChevron = styled.div<TypeStyledChevronProps>`
  margin-left: ${({ theme }) => theme.space[300]};
  ${({ $isOpen }) =>
    $isOpen &&
    `
		transform: rotate(-180deg);
	`}
  transition: transform 0.15s;

  ${({ invalid }) =>
    invalid &&
    css`
      color: ${(props) => props.theme.colors.icon.error};
    `}
`;

const StyledInnerButton = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ListboxToggleButton = ({
  children,
  ...buttonProps
}: TypeListboxToggleButtonProps) => {
  const { isOpen } = useMenuToggleContext();
  return (
    <StyledListboxToggleButton appearance="secondary" {...buttonProps}>
      <StyledInnerButton>
        {children}
        <StyledChevron $isOpen={isOpen} invalid={buttonProps.invalid}>
          <Icon name="chevron-down-outline" fixedWidth aria-hidden />
        </StyledChevron>
      </StyledInnerButton>
    </StyledListboxToggleButton>
  );
};
