import { useNestedMenuContext } from "./NestedMenuContext";
import {
  MenuPopout,
  type TypeMenuPopoutProps,
  type TypeMenuRootProps,
} from "../MenuRoot";
import { MenuToggleProvider } from "../MenuContext";
import { motion } from "motion/react";
import styled from "styled-components";

const StyledDiv = styled(motion.div)`
  overflow: hidden;
`;

export const NestedMenuPopout = ({
  content,
  menuToggleElement,
  width,
  ...popoutProps
}: Omit<TypeMenuPopoutProps, "children"> & {
  menuToggleElement: TypeMenuRootProps["menuToggleElement"];
}) => {
  const { activeMenuContext, rootMenuContextProps, resetSelectedMenuPath } =
    useNestedMenuContext();

  return (
    <MenuPopout
      {...popoutProps}
      // Reset the selected menu path after the close animation completes
      onClose={() => setTimeout(() => resetSelectedMenuPath?.(), 100)}
      isOpen={!!rootMenuContextProps.isOpen}
      openMenu={rootMenuContextProps.openMenu}
      closeMenu={rootMenuContextProps.closeMenu}
      width={width}
      content={(props) => (
        <StyledDiv layout="position">
          {typeof content === "function" ? content(props) : content}
        </StyledDiv>
      )}
    >
      {(toggleProps) => (
        <MenuToggleProvider
          // rootMenuContextProps overrides activeMenuContext since it handles open/close logic
          menuContext={{
            ...activeMenuContext,
            ...rootMenuContextProps,
            ...toggleProps,
          }}
        >
          {menuToggleElement}
        </MenuToggleProvider>
      )}
    </MenuPopout>
  );
};
